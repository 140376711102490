section#card-section {
    margin-top: -1px;
    margin-bottom: -1px;
    footer {
        margin-top: 15px;
    }
}
#bg-card-section {
    z-index: -1;
    margin-top: -1px;
    &:before,
    &:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 20vw;
        left: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        pointer-events: none;
    }
    &:before {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDEwMCA4LjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCA4LjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkY4RDIzIiBwb2ludHM9IjEwMCw4LjQgMCw4LjQgNTAsMCIvPjwvc3ZnPg==');
        top: 0;
        transform: translateY(calc(-100% + 1px));
        background-position: bottom center;
        @media screen and (orientation: landscape) {
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDEwMCA1LjEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwMCA1LjE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkY4RDIzIiBwb2ludHM9IjAsNS4xIDEwMCw1LjEgNTAsMCAiLz48L3N2Zz4NCg==');
        }
    }
    &:after {
        bottom: 1px;
        transform: translateY(100%);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxMDAgNi4zIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAgNi4zOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlsaW5lIGZpbGw9IiNFQTZGQzAiIHBvaW50cz0iNTAsNi4zIDEwMCwwIDAsMCA1MCw2LjMiLz48L3N2Zz4=");
    }
}

#card-container {
    transform: translate(0, -100%);
}
#card-wrapper {
    text-align: center;
    width: 33.3333%;
    min-width: 280px;
    max-width: 370px;
    z-index: 1;
    position: relative;
    img {
        width: 100%;
    }
    img#dilemo-card-front {
        width: 80%;
        transform: translateY(100%);
    }
    #dilemo-card-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        top: initial;
        transform: translateY(50%);
        z-index: 30;
    }
}
#card-front-wrapper {
    z-index: -1;
    display: inline-block;
    position: relative;
}

img#dilemo-dilema {
    width: 20vw;
}
.b-lazy {
    opacity: 0;
    transform: scale(.9);
    transition: all 150ms;
    &.b-loaded {
        transform: scale(1);
        opacity: 1;
    }
}

#yellow-section {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100vh;
        background-position: bottom;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0, calc(1px - 100%));
        background-size: 100% auto;
        pointer-events: none;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDEwMCAxNi4zIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDAgMTYuMzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5bGluZSBmaWxsPSIjRUVDMTQyIiBwb2ludHM9IjUwLjAzLDAgMC4wMywxNi4zIDEwMC4wMywxNi4zIDUwLjAzLDAiLz48L3N2Zz4=');
    }
}

#yellow-section-v2 {
    position: relative;
}
@font-face {
    font-family: 'Icons';
    src: url('../fonts/icons.woff2?3p9vin') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    font-family: 'Icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.pictoline:before { content: "\e901";}
    &.tiktok:before { content: "\e900";}
    &.close:before { content: "\e920";}
    &.facebook:before { content: "\ea90";}
    &.instagram:before { content: "\ea92";}
    &.twitter:before { content: "\ea96";}

}
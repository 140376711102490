body {
    --scroll-width: 12px;
    --scroll-thumb-color: #89cffc;
    --scroll-thumb-hover-color: #4f5af7;
    --scroll-bg-color: #6c75f3;
    --scroll-border-radius: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    // scroll-behavior: smooth;
}

// Firefox
* {
    scrollbar-width: thin;
    scrollbar-color: #8c87ff #eec142;
}

::-webkit-scrollbar {
    width: var(--scroll-width);
}

::-webkit-scrollbar-track {
    background-color: var(--scroll-bg-color);
    box-shadow: inset 0 0 0px #FFF; 
    border-radius: var(--scroll-border-radius);
}

::-webkit-scrollbar-thumb {
    background: var(--scroll-thumb-color); 
    border-radius: var(--scroll-border-radius);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-thumb-hover-color); 
}
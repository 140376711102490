// :root {
//     --yellow: #eec142;
//     --pink: #de62bf;
//     --purple: #4f5af7;
//     --blue: #9dd8f6;
//     --black: #161616;
// }

:root {
    --orange: #ff8d23;
    --yellow: #eec142;
    --green: #00bf79;
    --red: #ff0059;
    --pink: #ea6fc0;
    --purple: #5863FF;
    --blue: #55a6f7;
    --black: #161616;
}
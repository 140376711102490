header#main-header {
	height: auto;
	padding: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.75);
	
	&.in-top {
		position: absolute;
	}
	.button {
		padding: 0.6em 1.2em;
		line-height: 1.45em;
		height: 50px;
	}
}
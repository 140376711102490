.tick-list {
    li {
        &:before {
            content: "-";
            padding-right: 0.5em;
        }
        @media screen and (min-width: 768px) {
            position: relative;
            padding-left: 1.5em;
            &:before {
                content: "";
                background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxMy4zIDEzLjMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEzLjMgMTMuMzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxjaXJjbGUgZmlsbD0iIzAwQkY3OSIgY3g9IjYuNyIgY3k9IjYuNyIgcj0iNi4zIi8+PHBhdGggZD0iTTYuNywxMy4zQzMsMTMuMywwLDEwLjMsMCw2LjdTMywwLDYuNywwczYuNywzLDYuNyw2LjdTMTAuMywxMy4zLDYuNywxMy4zeiBNNi43LDAuOGMtMy4zLDAtNS45LDIuNi01LjksNS45IHMyLjYsNS45LDUuOSw1LjlzNS45LTIuNiw1LjktNS45UzkuOSwwLjgsNi43LDAuOHoiLz48cG9seWdvbiBmaWxsPSIjRkZGRkZGIiBwb2ludHM9IjUuMyw5LjQgMi45LDcgMy42LDYuNCA1LjMsOC4xIDkuNywzLjcgMTAuNCw0LjMiLz48L3N2Zz4=");
                width: 1em;
                height: 1em;
                display: inline-block;
                margin-right: 0.5em;
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: center;            
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0.15em;
            }
        }
    }
}